// Charge la base commune
import './common.js'

// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// OwlCarousel
async function getOwlCarousel () {
  import(/* webpackChunkName: "owlCarousel" */ './js/components/owlCarousel.js').then(() => {
    r()
  })
}

// Real Estate Search Form
async function getRESForm ($f) {
  $f.load()
  import(/* webpackChunkName: "realEstateSearchForm", webpackPrefetch: true */ './js/components/realEstateSearchForm.js').then(() => {
    $f.load(false)
  })
}

// Real Estate Locations Autocomplete
async function getRELAutocomplete ($f) {
  import(/* webpackChunkName: "realEstateLocationsAutocomplete" */ './js/components/realEstateLocationsAutocomplete.js').then(module => {
    const form = module.relAutocomplete
    form.init($f)
  })
}

// Masonry
async function getMasonry () {
  import(/* webpackChunkName: "masonry" */ './js/components/masonry.js').then(() => {
    r()
  })
}

// Real Estate Alert Form
async function getREAForm ($f) {
  $f.load()
  import(/* webpackChunkName: "realEstateAlertsForm" */ './js/components/realEstateAlertsForm.js').then(() => {
    $f.load(false)
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion du formulaire de recherche des biens immobiliers
  var $c = $('.resform:not(.modal)')
  if ($c.length) {
    $c.waypoint(function () {
      getRESForm($c)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion des carousel
  var $d = $('.owl-carousel.c')
  if ($d.length) {
    $d.waypoint(function () {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du masonry
  var $g = $('.grid')
  if ($g.length) {
    $g.waypoint(function () {
      getMasonry()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire d'inscription aux alertes
  var $f = $('.reaform')
  if ($f.length) {
    $f.waypoint(function () {
      getREAForm($f)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // filtres des offres autocomplete
  $('form[name="resForm"] input[name="search"]').on('click', function () {
    $(this).blur().off('click').attr('disabled', '')
    getRELAutocomplete($(this).parents('form'))
  })

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    r()
  })
})
