/* fichier JS de base commun quelques soient les versions du projet web */
// charge bootstrap => nécessite jquery et popper => il est préférable de décomposer bootstrap en sélectionnant les modules communs utilisés sur toutes les pages du site
// import 'bootstrap'
// exemples en ne chargeant que les modules ci-dessous (généralement utilisés pour le header du site)
import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'

// lazy loading => https://github.com/aFarkas/lazysizes
import lazySizes from 'lazysizes'

// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
// import 'bootstrap/scss/bootstrap'
import './scss/bootstrap'
// global styles
import './scss/custom'

// Scripts librairies
import { tClick } from './js/libs/utils'
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  var bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Real Estate Search Modal
async function getResModal ($f) {
  $f.load()
  import(/* webpackChunkName: "realEstateSearchModal" */ './js/components/realEstateSearchModal.js').then(() => {
    $f.load(false)
  })
}

// Scroll to top
async function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-up"></i>'
    })
  })
}

// Contact rapide
async function getQuickContact ($f) {
  $f.load()
  import(/* webpackChunkName: "quickContact" */ './js/components/quickContact.js').then(() => {
    $f.load(false)
  })
}

// Bouton de style pour le contact rapide
async function getQuickContactBtn () {
  import(/* webpackChunkName: "quickContactBtn" */ './scss/components/quickContactBtn.scss').then(() => {
    $('#quick-contact-btn').stop(true, true).animate({ opacity: 1 }, 300).removeClass('d-none')
  })
}

// footer
async function getCollapsingFooter () {
  import(/* webpackChunkName: "collapsingFooter" */ './js/components/collapsingFooter.js')
}

// sticky header
function sticky (_w) {
  var _h = $('#header')
  var s = 'sticky'
  if ((_w.scrollTop() > 32 && _w.width() > 991) || (_w.scrollTop() > 0 && _w.width() <= 991)) { _h.addClass(s) } else { _h.removeClass(s) }
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  var _w = $(window)

  // sur le scroll
  _w.scroll(function () {
    if (_w.scrollTop() >= 100 && !$('#scrollUp').length) { getScrollToTop() }
    sticky(_w)
  })

  // bouton recherche rapide
  $('.resModal').on('click', function () {
    $(this).off()
    getResModal($(this))
  })

  // bouton contact rapide
  $('#quick-contact-btn').on('click', function () {
    $(this).off()
    getQuickContact($(this))
  })

  // bouton collapsing footer
  $('#footer-collapsing-btn').on('click', function () {
    $(this).off()
    getCollapsingFooter($(this))
  })

  // navbar toggler
  $('[data-trigger]').on('click', function () {
    $($(this).attr('data-trigger')).toggleClass('show')
    $('.navbar-overlay').toggleClass('visible')
  })
  $('.navbar-close, .navbar-overlay').click(function () {
    $('.navbar-collapse').removeClass('show')
    $('.navbar-overlay').removeClass('visible')
  })

  // contact rapide
  if (!$('body').hasClass('error-404') && !$('body').hasClass('error-503')) {
    setTimeout(function () {
      getQuickContactBtn()
    }, 7000)
  }

  // handle sur toutes les pages du site
  sticky(_w)
  tClick()
})
